import { isNil, pick } from "lodash";
import React from "react";
import { useField } from "react-final-form";
import type { FieldRenderProps } from "react-final-form";
import i18n from "i18next";
import type { FieldViewProps } from "../features/FieldView/FieldView.types";

interface FieldOptions {
    defaultValue?: any;
    [key: string]: any;
}

export const useBindingField = ({
    defaultValue: defaultValueProps,
    definition: {
        fieldOptions: fieldOptionsProps,
        serialize,
        deserialize,
        validate: validateProps,
        dataOptions,
    },
    name,
    type,
    value,
    options,
    required,
    initialOptions,
    ...props
}: FieldViewProps): FieldRenderProps<any, HTMLElement> => {
    const currentName = React.useMemo(() => {
        if (props.useParentField) {
            return props.parentField ?? name;
        }
        return name;
    }, [name, props.useParentField, props.parentField]);

    const [{ defaultValue: defaultValueOptions = "", ...fieldOptions }] =
        React.useState<FieldOptions>(fieldOptionsProps || {});

    const [dataOptionsFromForm] = React.useState(
        pick(props, ["currency", "min", "entityTypes", "prefixField"]),
    );

    const parse = React.useCallback(
        (input: any) =>
            serialize({
                ...dataOptions,
                ...dataOptionsFromForm,
                language: i18n.language,
                options: initialOptions || options || [],
                type,
            })(input),
        [
            dataOptions,
            dataOptionsFromForm,
            initialOptions,
            options,
            serialize,
            type,
        ],
    );

    const defaultValueRaw = React.useMemo(() => {
        if (defaultValueProps === "" || isNil(defaultValueProps)) {
            return defaultValueOptions || "";
        }
        return defaultValueProps || "";
    }, [defaultValueOptions, defaultValueProps]);

    const defaultValue = React.useMemo(
        () => (value !== undefined ? value : parse(defaultValueRaw)),
        [defaultValueRaw, parse, value],
    );

    const validate = React.useCallback(
        (value: any) =>
            required !== false ? validateProps(options)(value) : null,
        [options, required, validateProps],
    );

    const fieldRenderProps = useField(currentName, {
        ...fieldOptions,
        defaultValue,
        format: deserialize,
        parse,
        validate,
    });

    React.useEffect(() => {
        if (
            !fieldRenderProps.meta.modified &&
            fieldRenderProps.meta.dirtySinceLastSubmit
        ) {
            fieldRenderProps.input.onChange(defaultValueRaw);
        }
    }, []);

    return fieldRenderProps;
};
