import { FC, ChangeEvent, CSSProperties, useMemo, ReactNode } from "react";
import classnames from "classnames";

import ValidationError from "../Errors/Validation";
import { isNil, toNumber } from "lodash";

enum ButtonType {
    default = "default",
    valid = "valid",
    invalid = "invalid",
}

interface InputFieldProps {
    className?: string;
    variant?: ButtonType;
    onChange: (value: number | string) => void;
    placeholder?: string;
    name: string;
    disabled?: boolean;
    readOnly?: boolean;
    addonBefore?: ReactNode | string;
    suffix?: ReactNode | string;
    preffix?: ReactNode | string;
    value: string;
    style?: CSSProperties;
    required?: boolean;
    type?: string;
    error?: string;
    wrapperStyle?: CSSProperties;
    autoFocus?: boolean;
}

export const InputField: FC<InputFieldProps> = ({
    onChange,
    name,
    className,
    placeholder,
    variant = "default",
    disabled,
    readOnly,
    value,
    suffix,
    addonBefore,
    preffix,
    style,
    required,
    type = "text",
    error,
    autoFocus,
    wrapperStyle,
    ...rest
}) => {
    const props: any = rest;

    const inputHasValue =
        (!isNil(value) && value !== "") || preffix ? "input-has-value" : null;

    const inputWrapClassName = (variant: string) => {
        switch (variant) {
            case "valid":
                return "input-valid";
            case "invalid":
                return "input-invalid";
            default:
                return "input-block";
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (type === "number") {
            const parsedToNumber = toNumber(e.target.value);
            const notNumber = !parsedToNumber && e.target.value !== "0";
            onChange(notNumber ? "" : parsedToNumber);
            return;
        }
        onChange(e.target.value);
    };

    const finalRequired = useMemo(
        () => required || (typeof props === "object" && props["aria-required"]),
        [required, props],
    );

    const classes = classnames("input", variant, className, inputHasValue);
    return (
        <div>
            {addonBefore && <div>{addonBefore}</div>}
            <div className="input-wrapper" style={wrapperStyle}>
                <div className={inputWrapClassName(variant)}>
                    <input
                        style={style}
                        className={classes}
                        onChange={handleInputChange}
                        value={
                            type === "number" && inputHasValue
                                ? Number(value).toString()
                                : value
                        }
                        disabled={disabled}
                        readOnly={readOnly}
                        onKeyDown={(evt) =>
                            type === "number" &&
                            !evt.metaKey &&
                            !evt.ctrlKey &&
                            evt.key.length < 2 &&
                            isNaN(evt.key as any) &&
                            evt.preventDefault()
                        }
                        type={type}
                        {...rest}
                    />
                    <label className="placeholder">
                        {finalRequired ? (
                            <span className="required">*</span>
                        ) : null}
                        {placeholder}
                    </label>
                    <span className="icon-preffix">{preffix}</span>
                    <span className="icon-suffix">{suffix}</span>
                </div>
                {error && <ValidationError error={error} />}
            </div>
        </div>
    );
};
