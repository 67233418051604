import { useEffect } from "react";
import type { FieldViewProps } from "../FieldView/FieldView.types";

export const TypeFieldInfo: React.FC<FieldViewProps> = ({
    label,
    value,
    useFAQ,
}) => {
    const faqContext = useFAQ?.();
    const dispatch = faqContext?.dispatch;

    useEffect(() => {
        if (dispatch) {
            dispatch({ content: { label, value }, type: "addContent" });
        }
    }, [dispatch, label, value]);

    return null;
};
