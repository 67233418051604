import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import pick from "lodash/pick";
import { Row, Popover } from "antd";
import { Label, Icon } from "data-view";
import { isNil } from "lodash";
import { useReactiveCalculation } from "../../hooks/useReactiveCalculation";
import { extendedLocaleToLang } from "data-model/src/shared/formatters";
import { usePermissions } from "../../hooks/usePermissions";
import { FEATURES } from "../../constants";
import {
    ButtonType,
    Happy,
    InnerButton,
    RightArrowIcon,
    Tag,
    Tooltip,
} from "../../components";
import { isNameInLabel } from "../../helpers";
import { AutoFillWithAIButton } from "./components/AutoFillWithAIButton";
import type { FieldViewProps } from "./FieldView.types";

export const FieldView: React.FC<FieldViewProps> = ({
    meta,
    input,
    user,
    definition: { View, fake, dataOptions },
    options,
    label,
    description,
    required,
    viewOnly,
    calculation,
    ...params
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const lang = useMemo(() => extendedLocaleToLang(language), [language]);

    // Extract relevant props
    const {
        prefix,
        asyncData,
        processInstance,
        userId,
        useFAQ,
        stepId,
        initialOptions,
        ...props
    } = params;

    const handleClick = useCallback(() => {
        const fakeValue = fake(
            Array.isArray(options) && options.length > 0
                ? options.map((option) => option.value || option)
                : undefined,
        );
        input.onChange(fakeValue);
    }, [fake, input, options]);

    const cleanDataOptions = useMemo(
        () => pick(dataOptions, ["min"]),
        [dataOptions],
    );

    const hasError = useMemo(() => meta.touched && meta.error, [meta]);

    const errorText = useMemo(() => {
        switch (meta.error) {
            case "requiredField":
                return t("common.error.required.generic");
            case "incorrectEmail":
                return t("common.error.input.email.invalid");
            default:
                return null;
        }
    }, [t, meta]);

    useEffect(() => {
        if (isNil(input.value) && props.defaultValue && !calculation) {
            setTimeout(() => {
                input.onChange(props.defaultValue);
            }, 30);
        }
    }, [input, props.defaultValue, calculation]);

    if (calculation) {
        useReactiveCalculation(input, calculation, prefix);
    }

    const processLabel = useMemo(
        () => processInstance?.product?.process?.label,
        [processInstance],
    );
    const isUsedInLabel = useMemo(
        () => processLabel && isNameInLabel(input.name || "", processLabel),
        [input, processLabel],
    );

    const admin = useMemo(
        () => user && usePermissions(user, FEATURES.superadmin),
        [user],
    );

    if (viewOnly) {
        return (
            <View
                className="form-runner-field-view"
                {...cleanDataOptions}
                {...props}
                {...input}
                {...{ lang, t, meta, options }}
            />
        );
    }

    return (
        <div
            className={classNames(
                "form-runner-field",
                props.type === "checkbox" && "form-runner-field--oneline",
            )}
        >
            <Row justify="space-between">
                {/* @ts-ignore */}
                <Label
                    required={required ?? true}
                    {...{ label }}
                    validateStatus={hasError ? "error" : "success"}
                >
                    {isUsedInLabel && (
                        <Tag size="small" type="draft">
                            {t("workflowv2Form.form.used")}
                        </Tag>
                    )}
                    {Boolean(description) && (
                        <Popover
                            align={{ offset: [0, 10] }}
                            content={description}
                            arrowPointAtCenter
                            overlayClassName="form-runner-field-moreinfo-overlay"
                        >
                            <InnerButton
                                type={ButtonType.link}
                                className="form-runner-field-moreinfo"
                            >
                                <span>
                                    {t("common.text.moreinfo")}{" "}
                                    <RightArrowIcon />
                                </span>
                            </InnerButton>
                        </Popover>
                    )}
                    {hasError && errorText && (
                        <Tag type="error" className="form-field-errortag">
                            &nbsp;
                            <Happy />
                            &nbsp;{errorText}&nbsp;
                        </Tag>
                    )}
                    {admin && ["file", "idCheck"].includes(props.type) && (
                        <AutoFillWithAIButton
                            processInstance={processInstance}
                            type={props.type}
                            frame={props.frame}
                            document={props.document}
                        />
                    )}
                </Label>
                {admin && (
                    <Tooltip
                        placement="left"
                        title={t("formRunner.fieldView.fake")}
                    >
                        <InnerButton
                            disabled={!fake}
                            type={ButtonType.text}
                            icon={<Icon width="24" name="dice" />}
                            onClick={handleClick}
                        />
                    </Tooltip>
                )}
            </Row>
            <Row key="form-field" justify="space-between">
                <View
                    className="form-runner-field-view"
                    {...{
                        language,
                        lang,
                        t,
                        ...cleanDataOptions,
                        ...props,
                        ...input,
                        meta,
                        options,
                    }}
                    status={hasError ? "error" : undefined}
                />
            </Row>
        </div>
    );
};
