import type { FC, Ref } from "react";

import { useDocument } from "./use";

import { DocumentMain } from "./DocumentMain";
import { loadToolbar } from "../../DocumentEditor/loadToolbar";
import { endpoint } from "../../DocumentEditor/endpoint";

// Ensure toolbar is loaded initially
loadToolbar();

// Define the props for the Document component
interface DocumentProps {
    syncfusionDocumentText?: boolean;
    editing?: boolean;
    reference?: Ref<any>;
    url?: string;
    [key: string]: any; // Allow additional props for flexibility
}

export const Document: FC<DocumentProps> = ({
    syncfusionDocumentText,
    editing,
    reference,
    imageClassName,
    onNextClick,
    onPreviousClick,
    url,
    ...properties
}) => {
    return (
        <DocumentMain
            content={useDocument(syncfusionDocumentText, url)}
            {...{
                ref: reference,
                serviceUrl: endpoint,
                ...properties,
            }}
        />
    );
};
