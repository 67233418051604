import type { ReactNode, FC } from "react";
import { getType } from "./getType";
import { usePDFProps } from "./Pdf/usePDF";

import { Pdf } from "./Pdf/Pdf";
import { Document } from "./Document/Document";
import { Text } from "./Text/Text";
import { Image } from "./Image/Image";
import { NotFound } from "./NotFound/NotFound";
import { ViewerWrapper } from "./ViewerWrapper";
import "./Viewer.less";

type DocumentType = "pdf" | "sfdt" | "html" | "image";

const SyncfusionDocumentText = "sfdt";

interface ViewerProps {
    mimetype: string;
    filename: string;
    url: string;
    children?: ReactNode;
    height?: string | number;
    detailedView?: boolean;
    ratio?: number;
    maxHeight?: number;
    onLoad?: (data: any) => void;
    editing: boolean;
    [key: string]: any; // Allow additional props for flexibility
}

interface DetailedViewerProps extends ViewerProps {
    mimetype: string;
}

const DetailedViewer: FC<DetailedViewerProps> = ({
    mimetype,
    filename,
    url,
    children,
    height,
    editing,
    ratio = 0.6, // Default ratio if not passed
    maxHeight = 1000, // Default maxHeight if not passed
    onLoad, // Optionally passed from parent
    ...props
}) => {
    const [type, typeName] = getType(filename, mimetype, url);

    const properties = { url, ...props };
    const allProperties = { filename, ...properties };
    const pdfProperties = usePDFProps({ ratio, maxHeight, onLoad });

    const renderContent = {
        pdf: () => <Pdf {...allProperties} {...pdfProperties} />,
        [SyncfusionDocumentText]: () => (
            <Document
                {...{ height, ...properties }}
                readOnly={!editing}
                syncfusionDocumentText={type === SyncfusionDocumentText}
            />
        ),
        html: () => <Text {...allProperties} />,
        image: () => <Image {...allProperties} />,
    };

    return (
        <ViewerWrapper
            type={typeName}
            {...{
                ...allProperties,
                ...(type === "pdf" && pdfProperties),
            }}
        >
            {children ?? renderContent[typeName as DocumentType]?.() ?? (
                <NotFound />
            )}
        </ViewerWrapper>
    );
};

export const Viewer: FC<ViewerProps> = ({
    detailedView = true,
    url,
    filename,
    ...props
}) =>
    detailedView ? (
        <DetailedViewer {...{ url, filename, ...props }} />
    ) : (
        <a className="document-link-icon" href={url}>
            {filename}
        </a>
    );
