import { useCallback, useState } from "react";
import jmespath from "jmespath";

import { useForm } from "react-final-form";
import { useTranslation } from "react-i18next";

import { FieldView } from "../FieldView/FieldView";
import { findFieldTypeInFrame, updateFieldForm } from "../../helpers/form";
import { useBindingField } from "../../hooks/useBindingField";
import { useImperativeQuery } from "../../hooks/useImperativeQuery";
import { getModel } from "data-model";

import {
    getCompanyInfoBySiretQuery,
    getCompanyBodaccEntriesQuery,
} from "../../graphql/queries";
import { messageError } from "../../components/Message";
import type { FieldViewProps } from "../FieldView/FieldView.types";

interface MappingField {
    field: string;
    path: string;
}

const __inseeFieldDefaultMapping: MappingField[] = [
    { field: "companyName", path: "uniteLegale.denominationUniteLegale" },
    { field: "companyHeadquarterAddress", path: "address" },
    {
        field: "companyHeadquarterZipCode",
        path: "adresseEtablissement.codePostalEtablissement",
    },
    {
        field: "companyHeadquarterCity",
        path: "adresseEtablissement.codeCommuneEtablissement",
    },
    { field: "companyHeadquarterCountry", path: "country" },
    { field: "companyForm", path: "uniteLegale.categorieJuridiqueUniteLegale" },
];

interface TypeFieldSiretProps extends FieldViewProps {
    mapping?: MappingField[];
}

export const TypeFieldSiret: React.FC<TypeFieldSiretProps> = ({
    frame,
    ...params
}) => {
    const form = useForm();
    const { input, meta } = useBindingField(params);
    const [noData, setNoData] = useState(false);
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const {
        user,
        prefix,
        definition,
        mapping = __inseeFieldDefaultMapping,
        ...props
    } = params;

    const [getCompanyInfo] = useImperativeQuery(getCompanyInfoBySiretQuery);
    const [getCompanyBodaccEntries] = useImperativeQuery(
        getCompanyBodaccEntriesQuery,
    );

    const getBodaccEntries = useCallback(
        async (value: string) => {
            const {
                data: { getCompanyBodaccEntries: bodaccEntries, error } = {},
            } = (await getCompanyBodaccEntries({ siret: value })) || {};

            if (error) {
                messageError(t("common.error.queryFailed"), error);
                return undefined;
            }
            return bodaccEntries || [];
        },
        [getCompanyBodaccEntries, t],
    );

    const handleImportClick = useCallback(
        async (value: string) => {
            try {
                const {
                    data: { getCompanyInfoBySiret: companyInfo, error } = {},
                } = (await getCompanyInfo({ siret: value })) || {};

                if (error) {
                    messageError(t("common.error.queryFailed"), error);
                    return;
                }

                if (companyInfo) {
                    if (companyInfo.errors && companyInfo.message) {
                        messageError(companyInfo.message, companyInfo.error);
                        return;
                    }
                    setNoData(false);
                    for (const item of mapping || []) {
                        const { field, path } = item;
                        try {
                            const fullField = prefix
                                ? `${prefix}.${field}`
                                : field;
                            const value = jmespath.search(companyInfo, path);
                            const typeDef: any =
                                findFieldTypeInFrame(fullField, frame) || {};
                            const model = getModel(typeDef);

                            if (model) {
                                updateFieldForm(
                                    { field: fullField, form, language },
                                    typeDef,
                                    model,
                                    value,
                                );
                            }
                        } catch (error) {
                            messageError(t("common.error.queryFailed"), error);
                        }
                    }
                } else {
                    setNoData(true);
                }
            } catch (error) {
                messageError(
                    t("common.error.queryFailed"),
                    (error as Error).message,
                );
                setNoData(true);
            }
        },
        [form, frame, getCompanyInfo, language, mapping, prefix, t],
    );

    return (
        <FieldView
            {...{
                definition,
                user,
                input,
                meta,
            }}
            handleImportClick={handleImportClick}
            errorMsg={
                noData
                    ? t("formRunner.fields.insee.companyNotFound")
                    : undefined
            }
            getBodaccEntries={getBodaccEntries}
            {...props}
        />
    );
};
