import { Field, FieldOption } from "../utils";

export const findFieldOptionsLabelByUuid = (data: Field[], uuid: string, lang: string | undefined ) : string | undefined => {
    for (const item of data) {
      if (item.options) {
        const option = item.options.find((opt: FieldOption) => opt.value === uuid);
        if (option) {
          return (lang || '').toLowerCase() === 'fr' ? option.label_fr : option.label;
        }
      }
    }
    return uuid;
}
  