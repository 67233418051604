import { forwardRef, useRef, useImperativeHandle } from "react";
import { loadToolbar } from "./loadToolbar";
import { endpoint } from "./endpoint";
import { DocumentEditorComponent } from "./DocumentEditorComponent";
import type { DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";

// Call loadToolbar at the top-level
loadToolbar();

// Define the props for DocumentEditor
interface DocumentEditorProps {
    onChange?: (serializedContent: string, evt: Event) => void;
    readOnly: boolean;
    [key: string]: any; // Additional props to be passed to Main
}

// Convert to a TypeScript functional component
export const DocumentEditor = forwardRef<
    DocumentEditorContainerComponent | null,
    DocumentEditorProps
>(({ onChange, ...properties }, reference) => {
    const ref = useRef<DocumentEditorContainerComponent | null>(null);

    // Expose the inner ref via useImperativeHandle
    useImperativeHandle(
        reference,
        () => ref.current as DocumentEditorContainerComponent,
    );

    // Handle content change events
    const contentChange = (evt: Event) => {
        if (onChange && ref.current?.documentEditor) {
            const serializedContent = ref.current.documentEditor.serialize();
            onChange(serializedContent, evt);
        }
    };

    return (
        <DocumentEditorComponent
            {...{
                ref,
                ...properties,
                contentChange,
                ...(ref.current && {
                    documentChange: contentChange,
                }),
            }}
            serviceUrl={endpoint}
            enableToolbar
            autoResizeOnVisibilityChange
        />
    );
});
